/* =========
  Squarespace Popups
  A Simple Popup Plugin for Squarespace
  This Code is Licensed by Will-Myers.com
========== */
.wm-popup-container {
  position: fixed;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100svh;

  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition-delay: var(--animation-duration, 0.3s);
}
.wm-popup-container > * {
  grid-area: 1 / 1;
}
.wm-popup-background {
  height: 100%;
  width: 100%;
  display: grid;
  position: absolute;
  top:0;
  place-items:center;
  backdrop-filter: blur(var(--blur, 0px)) opacity(0);
  -webkit-backdrop-filter: blur(var(--blur, 0px)) opacity(0);
}
.wm-popup-wrapper {
  position:relative;
  max-width: clamp(0px, var(--max-width, var(--sqs-site-max-width, 900px)), 90vw);
  width:100%;
  box-shadow: 0px 0px 12px hsla(0,0%,0%,.1);
  box-sizing:border-box;
}
.wm-popup-content {
  max-height:clamp(0px, var(--max-height, 900px), 80svh);
  overflow-y: auto;
  border-radius: var(--border-radius, 5px);
  border:var(--border, initial);
  position:relative;
  z-index: 1;
}
.wm-popup-content > .page-section {
  padding: var(--padding, 0px) !important;
}

/*Close Button*/
.wm-popup-close-btn{
  z-index: 2;
  position: absolute;
  top: calc(-1 * (var(--close-size, 2rem) / 2));
  right: calc(-1 * (var(--close-size, 2rem) / 2));
  height: var(--close-size, 2rem);
  width: var(--close-size, 2rem);
  display: grid;
  place-content: center;
  border-radius: 50%;
  border: 0;
  color: var(--close-color, var(--primaryButtonTextColor, white));
  background: var(--close-background, var(--primaryButtonBackgroundColor, black));
  padding: var(--close-padding, 6px);
  line-height: 0;
}
.wm-popup-close-btn svg{
  width: 100%;
  height: 100%;
}

/*Open*/
body.wm-popup-open {
  width: 100%;
}
.wm-popup-container.open {
  z-index:9999;
  opacity: 1;
  visibility: visible;
  transition: opacity 0s ease,
    visibilitiy 0s ease;
}
.wm-popup-container.open .wm-popup-background {
  background: hsla(var(--overlay-hsl, 0, 0%, 0%), var(--background-opacity, 0.6));
  backdrop-filter: blur(var(--blur, 0px)) opacity(1);
  -webkit-backdrop-filter: blur(var(--blur, 0px)) opacity(1);
}


/*Animations*/
.wm-popup-container[data-animation="none"],
.wm-popup-container[data-animation="none"] .wm-popup-background,
.wm-popup-container[data-animation="none"] .wm-popup-wrapper{
  --animation-duration: 0s !important;
}

.wm-popup-container[data-animation="slideup"] .wm-popup-background{
  transition: -webkit-backdrop-filter var(--animation-duration,0.3s) ease,
    backdrop-filter var(--animation-duration,0.3s) ease;
}
.wm-popup-container[data-animation="slideup"] .wm-popup-wrapper{
  transform: translateY(20px);
  opacity: 0;
  transition: transform var(--animation-duration, 0.3s) ease,
    opacity var(--animation-duration, 0.3s) ease;
}
.wm-popup-container[data-animation="slideup"].open .wm-popup-wrapper{
  transform: translateY(0px);
  opacity: 1;
}

.wm-popup-container[data-animation="slidedown"] .wm-popup-background{
  transition: -webkit-backdrop-filter var(--animation-duration,0.3s) ease,
    backdrop-filter var(--animation-duration,0.3s) ease;  
}
.wm-popup-container[data-animation="slidedown"] .wm-popup-wrapper{
  transform: translateY(-20px);
  opacity: 0;
  transition: transform var(--animation-duration, 0.3s) ease,
    opacity var(--animation-duration, 0.3s) ease;
}
.wm-popup-container[data-animation="slidedown"].open .wm-popup-wrapper{
  transform: translateY(0px);
  opacity: 1;
}

.wm-popup-container[data-animation="fade"] .wm-popup-background{
  transition: -webkit-backdrop-filter var(--animation-duration,0.3s) ease,
    backdrop-filter var(--animation-duration,0.3s) ease;
}
.wm-popup-container[data-animation="fade"] .wm-popup-wrapper{
  opacity: 0;
  transition: opacity var(--animation-duration, 0.3s) ease;
}
.wm-popup-container[data-animation="fade"].open .wm-popup-wrapper{
  opacity: 1;
}


/*Has Single Block*/
.single-block-only .content-wrapper > .sqs-block {
  display:block;
  padding: var(--single-block-padding, 0px);
  width: 100%;
}
.wm-popup-container.single-block-only:has(.content-wrapper > .sqs-block-video) .section-border {
  display: none;
}
.wm-popup-container.single-block-only .content-wrapper > .content {
  display:none;
}

.single-block-only .content-wrapper {
  padding: 0 !important;
}
.single-block-only .page-section {
  min-height:0vh !important;
}

/*Single Block Video Overrides*/
.single-block-only .content-wrapper > .sqs-block[data-block-json*="nativeVideo"] .embed-block-wrapper {
  /*For Native Video Blocks*/
  padding-bottom: 0 !important;
}
.single-block-only .content-wrapper > .sqs-block .embed-block-wrapper {
  /*For YT & Vimeo Blocks*/
}
.single-block-only .video-player .video-player__icons{
  top:0;
}

/*Video Sections*/
.single-block-container .sqs-block-video{
  padding:0px;
}
.single-block-container .embed-block-wrapper{
  padding-bottom: 0px !important;
}
.single-block-container .video-player .video-player__icons{
  top:0;
}

/*Remove padding around single block*/
.wm-popup-content > .sqs-block {
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
@media(min-width: 767px) {
  .wm-popup-content .fluid-engine{
    grid-template-columns: minmax(17px, 1fr) repeat(24, minmax(0, var(--cell-max-width))) minmax(17px, 1fr);
  }
}

/* Single Image Blocks */
.wm-popup-wrapper:has(.content-wrapper > .sqs-block-image .content-fit, .content-wrapper > .sqs-block-image [data-shape-mask]),
.wm-popup-wrapper:has(.content-wrapper > .sqs-block-image .content-fit, .content-wrapper > .sqs-block-image [data-shape-mask]) .wm-popup-content
.wm-popup-wrapper:has(.content-wrapper > .sqs-block-image .content-fit, .content-wrapper > .sqs-block-image [data-shape-mask]),
.wm-popup-wrapper:has(.content-wrapper > .sqs-block-image .content-fit, .content-wrapper > .sqs-block-image [data-shape-mask]) .content-wrapper {
  width: auto
}
.wm-popup-wrapper:has(.content-wrapper > .sqs-block-image .content-fit, .content-wrapper > .sqs-block-image [data-shape-mask]) .section-border{
  display:none;
}

/*Shape Block CSS*/
.sqs-block[data-definition-name="website.components.shape"] svg.sqs-shape {
  fill: var(--shape-block-background-color);
  stroke: var(--shape-block-stroke-color);
}
.sqs-block[data-definition-name="website.components.shape"] .sqs-shape-rectangle {
  background: var(--shape-block-background-color);
  border-color: var(--shape-block-stroke-color);
}
.sqs-block[data-definition-name="website.components.shape"] .sqs-block-content,
.sqs-block[data-definition-name="website.components.shape"] .sqs-block-alignment-wrapper {
  height: 100%;
}
.sqs-block[data-definition-name="website.components.shape"] .sqs-block-alignment-wrapper {
  display: flex;
}
.sqs-block[data-definition-name="website.components.shape"] .sqs-shape {
  display: block;
  position: absolute;
  overflow: visible;
}
.sqs-block[data-definition-name="website.components.shape"] .sqs-shape-block-container {
  position: relative;
  color: var(--shape-block-dropshadow-color);
}


/*Info Icon*/
a[data-wm-popup]:not(.gallery-grid-image-link, .sqs-block-image-link){
  position:relative;
}
.wm-info-icon{
  position:absolute;
  height: .8rem; 
  width: .8rem; 
  top:0;
  left:0;
}
.wm-info-icon svg{
  position:absolute;
  width:100%;
  height:100%;
}

p .wm-info-icon{
  height: .8rem; 
  width: .8rem; 
}
h1 .wm-info-icon, 
h2 .wm-info-icon{
  height: 1.5rem; 
  width: 1.5rem; 
}
h3 .wm-info-icon, 
h4 .wm-info-icon{
  height: 1.2rem; 
  width: 1.2rem; 
}
